import React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"

import { Row } from "../../components/base"
import { SimpleParagraph } from "../../components/content/paragraph"
import { Button } from "../../components/buttons/buttons"
import { Container } from "../../components/container/container"

import { useDimensions } from "../../hooks/useDimensions"
import useWindowSize, { getSize } from "../../hooks/useWindowSize"

import { StoryMeta } from "./storyMeta"
import { StoryImage } from "./storyImage"

import { shortSentence } from "../../helpers/truncateSentence"
import { Text } from "../../components/base/typo"

type StorySummaryProps = {
  image: any
  title: any
  date: any
  text: any
  link: any
  place: any
  linkText: any
}

export const StorySummary = ({
  image,
  title,
  date,
  text,
  link,
  place,
  linkText,
}: StorySummaryProps) => {
  const [ref, { width }] = useDimensions()
  const { type } = useWindowSize()
  const MAX_LENGTH = 224

  return (
    <div>
      <StoryContainer>
        <Container>
          <StoryMeta title={title} place={place} date={date} />
        </Container>
        <Container width="large">
          <div className="py-3" />
          <div ref={ref}>
            <StoryImage
              src={image}
              alt=""
              height={width / 2}
              minHeight={getSize(type, [150, 220, 300])}
            />
          </div>
          <div className="py-3" />
        </Container>
        <Container>
          <div className="my-3">
            <SimpleParagraph>
              <Text size="lg" weight="light">
                {shortSentence(text, MAX_LENGTH)}...
              </Text>
            </SimpleParagraph>
            <div className="py-5" />
            <Button link={link} text={linkText} />
          </div>
        </Container>
      </StoryContainer>
    </div>
  )
}

const StoryContainer = styled(Row)`
  ${tw`
        items-center
        py-16
        flex-wrap
    `}
`
