import Fuse from 'fuse.js';

const onSearch = (list: Array<any>, keyword: string, keys: Array<any> = []): Array<any> => {
  const options = {
    minMatchCharLength: 2,
    includeScore: true,
    keys,
  };

  const fuse = new Fuse(list, options);

  const result = fuse.search(keyword);
  let resSearch = result.filter((res) => (res.score && res.score < 0.73)).map((res) => res.item);
  if (resSearch.length === 0) {
    resSearch = [...list];
  }
  return resSearch
};

export default onSearch;
