import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import PageContainer from "../components/page-container"
import SEO from "../components/seo"
import { SimpleContentLayer } from "../components/contentLayer/contentLayer"
import { Input } from "../components/input/input"
import { TagButton } from "../components/buttons/buttons"
import { Container } from "../components/container/container"
import { MenuWrapper } from "../components/container/menuWrapper"

import { StorySummary } from "../content/stories/storySummary"

import useWindowSize from "../hooks/useWindowSize"
import { useDimensions } from "../hooks/useDimensions"

import onSearch from "../helpers/search"

import { SvgSearchIcon } from "../assets/svg"
import { formatImageData } from "../helpers/formatGraphqlData"

export const query = graphql`
  query StoriesPageQuery($locale: String) {
    allStrapiStory(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        slug
        location
        date
        chronologicalOrder
        featuredImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                srcSet
                src
                sizes
              }
            }
          }
        }
        shortDescription
        success
        updates
        research
      }
    }
    strapiStoryPage(locale: { eq: $locale }) {
      viewStories
    }
  }
`

const tagsType = ["research", "updates", "success"]

const StoriesPage = ({ data, intl }) => {
  const allStories = (data.allStrapiStory?.nodes ?? []).sort((sA, sB) => {
    // sort by chronologicalOrder field (number), the bigger the number the more recent the story
    //then stories that don't have chronologicalOrder field are sorted by date
    const orderA = sA?.chronologicalOrder
    const orderB = sB?.chronologicalOrder
    if (orderA && orderB) {
      return orderB - orderA
    } else if (orderA) {
      return -1
    } else if (orderB) {
      return 1
    } else {
      return new Date(sB?.date)?.getTime() - new Date(sA?.date)?.getTime()
    }
  })

  const { viewStories } = data.strapiStoryPage ?? {}

  const [filteredStories, setFilteredStories] = useState(allStories)
  const [tagsFilterd, setTagsFilterd] = useState<string[]>([])
  const [keyword, setKeyword] = useState("")

  const [ref] = useDimensions()
  const { type } = useWindowSize()

  const onFilter = (keyword: string, tags: Array<any>) => {
    const resSearch = onSearch(allStories, keyword, ["title"])
    const newStories = resSearch.filter(story => {
      return (
        (tags.includes("research") && story.research) ||
        (tags.includes("updates") && story.updates) ||
        (tags.includes("success") && story.success) ||
        tags.length === 0
      )
    })
    setFilteredStories(newStories)
  }

  const onChangeKeyword = e => {
    setKeyword(e)
  }

  const onChangeTags = (tag: string) => {
    const newTagsFiltered = tagsFilterd
    const index = newTagsFiltered.indexOf(tag)
    if (index > -1) {
      newTagsFiltered.splice(index, 1)
    } else {
      newTagsFiltered.push(tag)
    }
    setTagsFilterd([...newTagsFiltered])
  }

  //When keyword or tags changes it trigger onSearch function to filter
  useEffect(() => {
    onFilter(keyword, tagsFilterd)
  }, [keyword, tagsFilterd])

  return (
    <PageContainer>
      <SEO title="Stories" />
      <MenuWrapper pageName="stories">
        <SimpleContentLayer>
          <Container>
            <Input
              onChange={onChangeKeyword}
              placeholder="search stories..."
              value={keyword}
              icon={SvgSearchIcon}
            />
            <div className="flex justify-center">
              {tagsType.map((tag, index) => (
                <div key={index} className="mx-1">
                  <TagButton
                    onClick={() => onChangeTags(tag)}
                    text={tag}
                    key={index}
                    active={tagsFilterd.includes(tag)}
                    thin
                  />
                </div>
              ))}
            </div>
          </Container>
          <div>
            {filteredStories.map((story, index) => {
              const {
                title,
                location,
                date,
                featuredImage,
                shortDescription,
                slug,
              } = story
              return (
                <StorySummary
                  title={title}
                  image={formatImageData(featuredImage)}
                  text={shortDescription}
                  date={date}
                  place={location}
                  link={slug}
                  key={index}
                  linkText={viewStories}
                />
              )
            })}
          </div>
        </SimpleContentLayer>
      </MenuWrapper>
    </PageContainer>
  )
}

export default injectIntl(StoriesPage)
