export const shortSentence = (str, maxLength) => {
  if (!str) return ""
  let trimmedString = str?.substr(0, maxLength)

  //re-trim if we are in the middle of a word
  trimmedString = trimmedString?.substr(
    0,
    Math.min(trimmedString?.length, trimmedString?.lastIndexOf(" "))
  )
  return trimmedString
}
