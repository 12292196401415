import React from 'react'
import styled from "@emotion/styled";
import tw from "tailwind.macro";

type InputProps = {
    placeholder?: any,
    value: any,
    onChange: Function,
    rows?:number,
    maxlength?:number,
    icon?:any
}

export const Input = ({ placeholder, value, onChange, maxlength, icon }: InputProps) => {
    const Icon = icon;
    return <CustomInput>
            <input
                className='outline-none'
                type='text'
                value={value}
                onChange={(e)=> onChange(e.target.value)} placeholder={placeholder || ''}
                maxLength={maxlength}
            />
            <Icon />
        </CustomInput>
}

export const MultilineInput = ({ placeholder, value, onChange, maxlength, rows }: InputProps) =>
<CustomInput>
    <textarea
        value={value}
        onChange={(e)=> onChange(e.target.value)}
        placeholder={placeholder || ''}
        maxLength={maxlength}
        rows={rows}
    />
</CustomInput>

export const getStyledInput = styledDiv => styledDiv`
outline: 0;
border-radius: 12px;
${tw`
    flex
    items-center
    justify-between
    w-full
    py-1
    px-5
    my-6
    border
    border-primary21
    border-solid
`}
:hover {
    ${tw`shadow-lg`}
}
:focus {
    ${tw`shadow-lg outline-none`}
}
::placeholder,
> input::placeholder  {
    opacity: 0.6;
    ${tw`
        text-primary21
    `}
  }
`;

const CustomInput = getStyledInput(styled.div)